<template>
  <router-view :key="$route.path" />
</template>

<route>
{
"meta": {
"label": "佈景構建"
}
}
</route>

<script>
export default {
  name: "ThemeBuilding",
  layout: 'manage',
}
</script>
